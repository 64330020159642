import { ReactNode } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Error404 from '@/errors/4004Error';
import { useAppSelector } from '@/store';
import toast from 'react-hot-toast';
import { storage } from '@/utils';

type AuthGuardProps = {
    role: string;
    element: ReactNode;
};

export const AuthGuard = (props: AuthGuardProps) => {
    const { role, element } = props;

    const { user } = useAppSelector(state => state.userState);

    if (!user) return <Navigate to='/login'/>;
    if (role != user.role) {
        storage.removeToken();
        return <Navigate to={'/login'} />
    }

    return <>{element}</>;
};

type PublicGuardProps = {
    element: ReactNode;
};

export const PublicGuard = (props: PublicGuardProps) => {
    const { element } = props;

    const { user } = useAppSelector(state => state.userState);

    if (user) return <Navigate to='/' />;
    return <>{element}</>;
};

