import config from '@/config';
import { storage } from '@/core/utils';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: config.API_URL,
		prepareHeaders: headers => {
			const token = storage.getToken();
			if (token) headers.set('authorization', `Bearer ${token}`);

			return headers;
		},
	}),
    tagTypes: [
        'Users',
        'Categories',
        'Category',
        'Questions',
        'Question',
        'Levels',
        'Level',
        'CLASSES',
        'CLASS',
        'CLASS-Q'
    ],
	endpoints: () => ({}),
});

export default baseApi;
