import { LoginModel, LoginInput } from '@/app/auth';
import { UserInfo, UserModel, userSlice } from '@/app/user';
import baseApi from '@/core/lib/base';
import { ResponseModel } from '@/core/types';
import { storage } from '@/utils';
import { SignupInput } from '@/app/auth';
import toast from 'react-hot-toast';

export const authApi = baseApi.injectEndpoints({
	endpoints: builder => ({
		login: builder.mutation<LoginModel, LoginInput>({
			query: payload => ({
				method: 'POST',
				url: 'api/v1/user/login',
				body: payload,
			}),
			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled;
                    console.log(data);
					storage.setToken(data.access_token);
					dispatch(userSlice.actions.setUser(data.userInfo));
				} catch (error: any) {
                    toast.error(error.error.data.message);
				}
			},
		}),

		signup: builder.mutation<ResponseModel<null>, SignupInput>({
			query: payload => ({
				method: 'POST',
				url: 'api/v1/user/signup',
				body: payload,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Registration request submited');
				} catch (error: any) {
                    toast.error(error.error.data.message);
                }
			},
		}),

		whoami: builder.query<UserModel, null>({
			query: () => ({
				method: 'GET',
				url: 'api/v1/user/check',
			}),
			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled;
					dispatch(userSlice.actions.setUser(data));
				} catch (error) {
					storage.removeToken();
					dispatch(userSlice.actions.logout());
				}
			},
		}),
	}),
});

export const { useLoginMutation, useSignupMutation, useWhoamiQuery } = authApi;
