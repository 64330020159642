import { Link, Outlet, useLocation } from 'react-router-dom';
import { ClipboardDocumentListIcon, PlusIcon } from '@heroicons/react/24/outline';
import { format } from '@/utils';
import {
    TrashIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import Confirmation from '@/components/confirmation';
import { useQuestionDeleteMutation, useQuestionListQuery } from '../redux/api';
import { QuestionModel } from '@/app/questions/types';
import { EyeIcon } from '@heroicons/react/16/solid';
import { CreateQuestionMainModal } from '@/app/questions/components/modals/create_main';

export const QuestionList = () => {
    const { user } = useAppSelector(state => state.userState);

    const location = useLocation();

    const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const params: { [key: string]: string } = {};

        searchParams.forEach((value, key) => {
            params[key] = value;
        });

        setQueryParams(params);
    }, [location.search]);

    const { isFetching, data } = useQuestionListQuery(queryParams);

    const [onDelete, { isLoading: isLoadingDelete }] = useQuestionDeleteMutation();

    const [open , setOpen] = useState(false);

    const columns: TableColumn<QuestionModel>[] = [
        {
            accessor: 'title',
            title: 'Title',
            render: row => row.title,
        },
        {
            accessor: 'marks',
            title: 'Marks',
            render: row => row.marks,
        },
        {
            accessor: 'type',
            title: 'Type',
            render: row => row.type,
        },
        {
            accessor: 'created_at',
            title: 'Created',
            render: row => format.dateTime(row.created_at),
        },
        {
            accessor: ' ',
            title: '',
            render: row =>
                row.id !== user?.id && (
                    <div className="flex justify-end gap-0 p-1">
                        <Confirmation
                            title={'Delete Category'}
                            loading={isLoadingDelete}
                            onConfirm={async () => {
                                await onDelete({ id: row.id });
                            }}
                        >
                            {({ onOpen }) => (
                                <button title={'Delete Category'} className={'p-2 bg-red-500 rounded-lg text-white'} onClick={onOpen}>
                                    <TrashIcon className="icon-sm w-5" />
                                </button>
                            )}
                        </Confirmation>
                    </div>
                ),
        },
    ];

    return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Questions</span>
                </li>
            </ul>
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} title='Users'>
                <h1 className={'p-4 text-lg'}>All Questions</h1>
                {user?.role === 'admin' && <></>}
            </div>

            <ExportDataTable
                data={data?.list ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Users"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isFetching}
                showSearch={true}
            />

            <Outlet />
        </>
    );
};
