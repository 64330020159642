import React, { useEffect, useState } from 'react';
import { Modal } from '@/components/modal/modal';
import { CameraIcon, DocumentDuplicateIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { AppLoader } from '@/components/loader/loader';
import { ClassQuestionPayload } from '@/app/class/types';
import { useClassQuestionCreateMutation } from '@/app/class/redux/api';

export const CreateClassQuestionModal = (props: { classCode: string | number }) => {

    const { classCode } = props;

    const [onCreate, { isSuccess, isLoading }] = useClassQuestionCreateMutation();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [marks, setMarks] = useState('');
    const [selectedImage, setSelectedImage] = useState<string | undefined>();
    const [open, setOpen] = useState(false);

    const [arrAnswers, setArrAnswers] = useState<{ title: string, correct: boolean }[]>([{
        title: '',
        correct: false
    }]);

    const [checkedIndex, setCheckedIndex] = useState<number | null>(null);

    const handleAddClick = () => {
        if(arrAnswers[arrAnswers.length - 1].title != '') {
            setArrAnswers(prevArrAnswers => [...prevArrAnswers, { title: '', correct: false }]);
        } else {
            toast.error('Fill First Input')
        }
    };

    const handleInputChange = (index: number, value: string) => {
        setArrAnswers(prevArrAnswers => {
            const updatedArrAnswers = [...prevArrAnswers];
            updatedArrAnswers[index].title = value;
            return updatedArrAnswers;
        });
    };

    const handleCheckboxChange = (index: number) => {
        setCheckedIndex(index);
        setArrAnswers(prevArrAnswers => {
            const updatedArrAnswers = [...prevArrAnswers];
            updatedArrAnswers.forEach((answer, i) => {
                answer.correct = i === index;
            });
            return updatedArrAnswers;
        });
    };

    const handleRemove = (indexToRemove: number) => {
        setArrAnswers(prevState => prevState.filter((_, index) => index !== indexToRemove));
    };


    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                if (e.target) {
                    setSelectedImage(e.target.result as string);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const handleButtonClick = () => {
        setOpenLink(false);
        const inputElement = document.getElementById('fileInput');
        if (inputElement) {
            inputElement.click();
        }
    };

    const handleSubmitMatching = async () => {
        if(marks === '') {
            return toast.error('Marks are required');
        }

        const payload: ClassQuestionPayload = {
            title: title,
            description,
            marks: parseInt(marks, 10),
            icon: selectedImage,
            answers: arrAnswers,
        }

        if(payload.title === '') {
            return toast.error('Title is required');
        }

        console.log(payload);

        await onCreate({ id: classCode, data: payload });
    }

    const disableButton = () => {
        return (arrAnswers.length < 2);
    }

    useEffect(() => {
        if(isSuccess) {
            setMarks('');
            setTitle('');
            setArrAnswers([{ title: '', correct: false }]);
            setSelectedImage(undefined);
            setOpen(false);
        }
    }, [isSuccess]);

    const [openLink, setOpenLink] = useState(false);

    return (
        <>
            {<div onClick={() => setOpen(true)}
                                            className="btn btn-primary btn-sm">
                <PlusIcon className="icon-sm w-6" />
                <p className={'flex flex-row gap-1'}>New Question</p>
            </div>}

            <Modal opened={open} onClose={() => setOpen(false)} title={'Create Question'} className={'max-w-3xl'}>
                <>
                    <div className="gap-2 grid grid-cols-1">
                        <div className="flex flex-col md:flex-row gap-2">
                            <div className="w-full md:w-3/5">
                                <div className="mb-1">
                                    <label>Title</label>
                                    <input
                                        type={'text'}
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        placeholder={'Enter Title'}
                                        className={'p-2 rounded-md border border-gray-200'}
                                    />
                                </div>
                                <div className={'grid grid-cols-2 gap-2'}>
                                    <div className="">
                                        <label>Marks</label>
                                        <input
                                            type={'number'}
                                            value={marks}
                                            onChange={(e) => setMarks(e.target.value)}
                                            placeholder={'Enter Marks'}
                                            className={'p-2 rounded-md border border-gray-200'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full flex-col gap-1  md:w-2/5">
                                {openLink &&
                                    <div className={''}>
                                        <label>Image Link</label>
                                        <input
                                            type={'text'}
                                            value={selectedImage}
                                            onChange={(e) => setSelectedImage(e.target.value)}
                                            placeholder={'Enter Image URL'}
                                            className={'p-2 rounded-md border border-gray-200'}
                                        />
                                    </div>
                                }
                                <div className="flex flex-row justify-start gap-2 items-center w-full">
                                    <div className="flex flex-end"><XMarkIcon
                                        onClick={() => {
                                            setSelectedImage('');
                                        }} className={'w-5 rounded bg-red-500 text-white p-1 cursor-pointer'} />
                                    </div>

                                    <div className="flex flex-end">
                                        <DocumentDuplicateIcon
                                            onClick={() => setOpenLink(true)} title={'Paste Link'}
                                            className={'w-5 rounded bg-green-500 text-white p-1 cursor-pointer'} />
                                    </div>
                                </div>

                                <div
                                    className="border-2 h-full border-dashed border-primary/40 text-primary/50 rounded-md flex gap-2 justify-center items-center">
                                    {selectedImage ? <img src={selectedImage} onClick={handleButtonClick}
                                                          className={'cursor-pointer h-[175px] w-[290px]'}
                                                          alt={'selected image'} /> :
                                        <div className={'cursor-pointer flex flex-col items-center'}
                                             onClick={handleButtonClick}>
                                            <CameraIcon className={'w-10 '} />
                                            <input
                                                id="fileInput"
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                style={{ display: 'none' }}
                                            />
                                            <p className={'text-xs'}>Add Image</p>
                                        </div>}
                                </div>
                            </div>
                        </div>


                        {<div>
                            <div className="flex flex-row justify-between items-center">
                                <label>Create Answers (tick correct)</label>
                                <div className="flex flex-row py-2">
                                    <button onClick={handleAddClick}
                                            className={'p-0 rounded-sm bg-green-600 text-white'}><PlusIcon
                                        className={'w-5'} /></button>
                                </div>
                            </div>
                            <div className="">
                                {arrAnswers.map((answer, index) => (
                                    <div key={index}
                                         className={'flex mb-2 justify-between flex-row gap-2 items-center'}>
                                        <input
                                            type={'text'}
                                            value={answer.title}
                                            className={'p-2 rounded-md border border-gray-200'}
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                        />
                                        <div className="flex items-center gap-2">
                                            <input
                                                id={`checkbox-${index}`}
                                                type="checkbox"
                                                checked={checkedIndex === index}
                                                onChange={() => handleCheckboxChange(index)}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            />
                                            {index != 0 ? <button onClick={() => handleRemove(index)}
                                                                  className={''}><XMarkIcon
                                                className={'w-3'} /></button> : <div className={'w-3'}></div>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>}

                        <div className="mb-1">
                            <label>Description</label>
                            <input
                                type={'text'}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder={'Enter Answer Description'}
                                className={'p-2 rounded-md border border-gray-200'}
                            />
                        </div>

                        {isLoading ? <button className={'btn btn-primary btn-sm w-28 py-0'}><AppLoader width={'w-5'}
                                                                                                       height={'h-10'} />
                        </button> : <button onClick={handleSubmitMatching} disabled={disableButton()}
                                            className={'btn btn-primary btn-sm w-28 py-3'}>Create</button>}
                    </div>
                </>
            </Modal>

        </>
    )
}
