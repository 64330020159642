import * as z from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '@/app/auth/redux';
import { useEffect } from 'react';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import { Button } from '@mantine/core';
import toast from 'react-hot-toast';

const schema = z.object({
	username: z.string().min(1, 'Username is required'),
	password: z.string().min(1, 'Password required'),
});

export type LoginInput = z.TypeOf<typeof schema>;

export const Login = () => {
	const navigate = useNavigate();

	const [onLogin, { isLoading, isSuccess,}] = useLoginMutation();

	useEffect(() => {
		if (isSuccess) navigate('/');
	}, [isSuccess]);

	return (
        <>
            <div>
                <h2 className='text-lg font-semibold text-primary/90'>Sign In</h2>
            </div>

            <Form<LoginInput, typeof schema>
                schema={schema}
                onSubmit={payload => onLogin(payload)}
                className='mt-5'
            >
                {({ register, formState }) => (
                    <>
                        <div className='space-y-4'>
                            <InputField
                                type='text'
                                label='Email Or Username'
                                placeholder='Enter Your Username'
                                focus
                                loading={isLoading}
                                error={formState.errors.username}
                                registration={register('username')}

                            />

                            <InputField
                                type='password'
                                label='Password'
                                placeholder='Enter Password'
                                loading={isLoading}
                                error={formState.errors.password}
                                registration={register('password')}

                            />
                        </div>

                        <button
                            type='submit'
                            disabled={isLoading}
                            className='mt-6 w-full font-semibold uppercase py-3 btn btn-primary'
                        >
                            Sign In
                        </button>
                    </>
                )}
            </Form>
            {/*<p className='mt-10 w-full text-center text-lg text-gray-500'>*/}
            {/*    Don't have an account?{' '}*/}
            {/*    <Link to='/signup' className='font-medium text-blue-600'>*/}
            {/*        Sign Up*/}
            {/*    </Link>*/}
            {/*</p>*/}
        </>
    );
};
