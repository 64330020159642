import React, { useEffect, useState } from 'react';
import { Modal } from '@/components/modal/modal';
import { CameraIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import * as z from 'zod';
import { InputField } from '@/components/input';
import { Form } from '@/components/form';
import { useCategoryCreateMutation } from '@/app/categories/redux/api';

const schema = z.object({
    name: z.string().min(1, 'Name is required'),
    description: z.string().min(1, 'Description is required'),
});

export type CategoryInput = z.TypeOf<typeof schema>;


export const CreateCategoryModal = (props: { isProvision: boolean }) => {

    const { isProvision } = props;

    const { user } = useAppSelector((state) => state.userState);

    const [open, setOpen] = useState(false);

    const [onCreate,  { isLoading, isSuccess }] = useCategoryCreateMutation();

    const [selectedImage, setSelectedImage] = useState<string | undefined>();

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                if (e.target) {
                    setSelectedImage(e.target.result as string);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const handleButtonClick = () => {
        const inputElement = document.getElementById('fileInput');
        if (inputElement) {
            inputElement.click();
        }
    };

    useEffect(() => {
        if(isSuccess) {
            setOpen(false);
        }
    }, [isSuccess]);

    return (
        <>
            {user?.role === 'admin' && <div onClick={() => setOpen(true)}
                                            className="btn btn-primary btn-sm">
                <PlusIcon className="icon-sm w-6" />
                <p className={'flex flex-row gap-1'}>New Category</p>
            </div>}

            <Modal opened={open} onClose={() => setOpen(false)} title={'Create Category'} className={'max-w-lg'}>
                <Form<CategoryInput, typeof schema> schema={schema} onSubmit={(val) =>
                    onCreate({  name: val.name, description: val.description, icon: selectedImage, isProvision: isProvision })}>
                    {({ register, formState }) => (
                        <>
                            <div className='space-y-4'>
                                <div className='grid grid-cols-1 gap-2'>
                                    <div className="">
                                        <InputField
                                            type='text'
                                            label='Name'
                                            loading={isLoading}
                                            error={formState.errors.name}
                                            registration={register('name')}

                                        />
                                    </div>
                                </div>
                                <InputField
                                    type='text'
                                    label='Description'
                                    loading={isLoading}
                                    error={formState.errors.description}
                                    registration={register('description')}

                                />

                                <div className="flex w-full flex-col gap-1">
                                    <div className="flex w-full flex-end"><XMarkIcon
                                        onClick={() => setSelectedImage(undefined)} className={'w-5 rounded bg-red-500 text-white p-1 cursor-pointer'} />
                                    </div>
                                    <div
                                        className="border-2 h-full border-dashed border-primary/40 text-primary/50 rounded-md flex gap-2 justify-center items-center">
                                        {selectedImage ? <img src={selectedImage} onClick={handleButtonClick}
                                                              className={'cursor-pointer w-full'}
                                                              alt={'selected image'} /> :
                                            <div className={'cursor-pointer my-10 flex flex-col items-center'}
                                                 onClick={handleButtonClick}>
                                                <CameraIcon className={'w-10 '} />
                                                <input
                                                    id="fileInput"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    style={{ display: 'none' }}
                                                />
                                                <p className={'text-xs'}>Add Image</p>
                                            </div>}
                                    </div>
                                </div>

                            </div>

                            <button type='submit' disabled={isLoading}
                                    className='mt-6 py-3 w-20 btn btn-primary btn-sm bg-primary'>
                                Create
                            </button>
                        </>
                    )}
                </Form>
            </Modal>

        </>
    )
}
