import React, { useEffect, useState } from 'react';
import { Modal } from '@/components/modal/modal';
import { CameraIcon, PencilIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import { QuestionModel, QuestionPayloadUpdate } from '@/app/questions/types';
import toast from 'react-hot-toast';
import { useQuestionUpdateMutation } from '@/app/questions/redux/api';
import { AppLoader } from '@/components/loader/loader';

export const UpdateQuestionModal = (props: { question: QuestionModel }) => {

    const { question } = props;

    const { user } = useAppSelector((state) => state.userState);
    const [onCreate, { isSuccess, isLoading }] = useQuestionUpdateMutation();

    const [title, setTitle] = useState(question.title);
    const [description, setDescription] = useState(question.description ?? '');
    const [marks, setMarks] = useState(`${question.marks}`);
    const [selectedImage, setSelectedImage] = useState<string | undefined>(question.icon);
    const [open, setOpen] = useState(false);
    const [isTrue, setIsTrue] = useState(question.type == 'trueOrFalse' ? getCorrectAnswer(question.answer ?? [{ title: '', correct: false }]) : false );

    const [arrAnswers, setArrAnswers] = useState<{ title: string, correct: boolean }[]>( []);

    function getCorrectAnswer(data: { title: string, correct: boolean }[]): boolean {
        const matchingAnswer = data.find((item) => item.title === 'True');
        return matchingAnswer?.correct ?? false; // Use nullish coalescing for default
    }

    useEffect(() => {
        setTitle(question.title);
        setMarks(`${question.marks}`);

        if(question.type == 'multipleChoice') {
            setArrAnswers(question.answer ?? []);
            question.answer?.map((item, index) => {
                if(item.correct) {
                    setCheckedIndex(index);
                }
            })
        }

        if(question.type == 'matching') {
            const m = question.matchingOptions;
            setArrAnswersMatching(m.map((m) => m) ?? []);
        }
    }, []);

    const [arrAnswersMatching, setArrAnswersMatching] = useState<{ leftOption: string, rightOption: string }[]>([{
        leftOption: '',
        rightOption: ''
    }]);

    const [checkedIndex, setCheckedIndex] = useState<number | null>(null);

    const handleAddClick = () => {
        if(arrAnswers[arrAnswers.length - 1].title != '') {
            setArrAnswers(prevArrAnswers => [...prevArrAnswers, { title: '', correct: false }]);
        } else {
            toast.error('Fill First Input')
        }
    };

    const handleInputChange = (index: number, value: string) => {
        setArrAnswers(prevArrAnswers => {
            const updatedArrAnswers = [...prevArrAnswers];
            updatedArrAnswers[index].title = value;
            return updatedArrAnswers;
        });
    };

    const handleCheckboxChange = (index: number) => {
        setCheckedIndex(index);
        setArrAnswers((prevArrAnswers) => {
            // Create a new array to hold updated answers
            return prevArrAnswers.map((answer, i) => {
                // Return a new object for each answer with updated 'correct' property
                return { ...answer, correct: i === index };
            });
        });
    };

    const handleRemove = (indexToRemove: number) => {
        setArrAnswers(prevState => prevState.filter((_, index) => index !== indexToRemove));
    };

    const handleAddClickMatching = () => {
        if(arrAnswersMatching[arrAnswersMatching.length - 1].rightOption.length === 0 ||
            arrAnswersMatching[arrAnswersMatching.length - 1].leftOption.length === 0 ) {
            toast.error('Fill Matching Options');
        } else {
            setArrAnswersMatching(prevArrAnswers => [...prevArrAnswers, { leftOption: '', rightOption: '' }]);
        }
    };

    const handleInputChangeMatchingL = (index: number, value: string) => {
        setArrAnswersMatching(prevArrAnswers => {
            // Create a copy of the previous array
            const updatedArrAnswers = [...prevArrAnswers];

            // Update the leftOption property of the object at the specified index
            updatedArrAnswers[index] = {
                ...updatedArrAnswers[index],
                leftOption: value,
            };

            // Return the new array with the modification
            return updatedArrAnswers;
        });
    };

    const handleInputChangeMatchingR = (index: number, value: string) => {
        setArrAnswersMatching(prevArrAnswers => {
            const updatedArrAnswers = [...prevArrAnswers];
            updatedArrAnswers[index] = {
                ...updatedArrAnswers[index],
                rightOption: value,
            };
            return updatedArrAnswers;
        });
    };


    const handleRemoveMatching = (indexToRemove: number) => {
        setArrAnswersMatching(prevState => prevState.filter((_, index) => index !== indexToRemove));
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                if (e.target) {
                    setSelectedImage(e.target.result as string);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const handleButtonClick = () => {
        const inputElement = document.getElementById('fileInput');
        if (inputElement) {
            inputElement.click();
        }
    };

    const handleSubmitMatching = async () => {
        if(marks === '') {
            return toast.error('Marks are required');
        }

        const payload: QuestionPayloadUpdate = question.type === 'matching' ? {
            title: title,
            description,
            marks: parseInt(marks, 10),
            icon: selectedImage,
            matchingOptions: arrAnswersMatching,
        } : question.type === 'multipleChoice' ? {
            title: title,
            description,
            marks: parseInt(marks, 10),
            icon: selectedImage,
            answers: arrAnswers,
        } : {
            title: title,
            description,
            marks: parseInt(marks, 10),
            icon: selectedImage,
            answers: [{ title: 'True', correct: isTrue }, { title: 'False', correct: !isTrue }],
        }

        if(payload.title === '') {
            return toast.error('Title is required');
        }

        console.log(payload);

        await onCreate({ data: payload, id: question.id });
    }

    const disableButton = () => {
        if(question.type === 'matching' && arrAnswersMatching.length < 2) return true;
        return (question.type === 'multipleChoice' && arrAnswers.length < 2);
    }

    useEffect(() => {
        if(isSuccess) {
            setOpen(false);
        }
    }, [isSuccess]);

    useEffect(() => {

    }, []);

    return (
        <>
            {user?.role === 'admin' &&
                <button title={'Update Question'} className={'p-2 bg-green-500 rounded-r-lg text-white'} onClick={() => setOpen(true)}>
                    <PencilIcon className="icon-sm w-5" />
                </button>}

            <Modal opened={open} onClose={() => setOpen(false)} title={'Edit Question'} className={'max-w-3xl'}>
                <>
                    <div className="gap-2 grid grid-cols-1">
                        <div className="flex flex-col md:flex-row gap-2">
                            <div className="w-full md:w-3/5">
                                <div className="mb-1">
                                    <label>Title</label>
                                    <input
                                        type={'text'}
                                        defaultValue={question.title}
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        placeholder={'Enter Title'}
                                        className={'p-2 rounded-md border border-gray-200'}
                                    />
                                </div>
                                <div className={'grid grid-cols-2 gap-2'}>
                                    <div className="">
                                        <label>Marks</label>
                                        <input
                                            type={'number'}
                                            value={marks}
                                            defaultValue={question.marks}
                                            onChange={(e) => setMarks(e.target.value)}
                                            placeholder={'Enter Marks'}
                                            className={'p-2 rounded-md border border-gray-200'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full flex-col gap-1  md:w-2/5">
                                <div className="flex w-full flex-end"><XMarkIcon
                                    onClick={() => setSelectedImage(undefined)} className={'w-4 cursor-pointer'} />
                                </div>
                                <div
                                    className="border-2 h-full border-dashed border-primary/40 text-primary/50 rounded-md flex gap-2 justify-center items-center">
                                    {selectedImage ? <img src={selectedImage} onClick={handleButtonClick}
                                                          className={'cursor-pointer h-[175px] w-[290px]'}
                                                          alt={'selected image'} /> :
                                        <div className={'cursor-pointer flex flex-col items-center'}
                                             onClick={handleButtonClick}>
                                            <CameraIcon className={'w-10 '} />
                                            <input
                                                id="fileInput"
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                style={{ display: 'none' }}
                                            />
                                            <p className={'text-xs'}>Add Image</p>
                                        </div>}
                                </div>
                            </div>
                        </div>


                        {question.type === 'multipleChoice' && <div>
                            <div className="flex flex-row justify-between items-center">
                                <label>Create Answers (tick correct)</label>
                                <div className="flex flex-row py-2">
                                    <button onClick={handleAddClick}
                                            className={'p-0 rounded-sm bg-green-600 text-white'}><PlusIcon
                                        className={'w-5'} /></button>
                                </div>
                            </div>
                            <div className="">
                                {arrAnswers.map((answer, index) => (
                                    <div key={index}
                                         className={'flex mb-2 justify-between flex-row gap-2 items-center'}>
                                        <input
                                            type={'text'}
                                            value={answer.title}
                                            className={'p-2 rounded-md border border-gray-200'}
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                        />
                                        <div className="flex items-center gap-2">
                                            <input
                                                id={`checkbox-${index}`}
                                                type="checkbox"
                                                checked={checkedIndex === index}
                                                onChange={() => handleCheckboxChange(index)}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            />
                                            {index != 0 ? <button onClick={() => handleRemove(index)}
                                                                  className={''}><XMarkIcon
                                                className={'w-3'} /></button> : <div className={'w-3'}></div>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>}

                        {question.type === 'matching' && <div>
                            <div className="flex flex-row justify-between items-center">
                                <label>Create Matching Answers (each with its correct value)</label>
                                <div className="flex flex-row py-2">
                                    <button onClick={handleAddClickMatching}
                                            className={'p-0 rounded-sm bg-green-600 text-white'}><PlusIcon
                                        className={'w-5'} /></button>
                                </div>
                            </div>
                            <div className="">
                                {arrAnswersMatching.map((answer, index) => (
                                    <div key={index}
                                         className={'flex mb-2 justify-between flex-row gap-2 items-center'}>
                                        <input
                                            type={'text'}
                                            value={answer.leftOption}
                                            className={'p-2 rounded-md border border-gray-200'}
                                            onChange={(e) => handleInputChangeMatchingL(index, e.target.value)}
                                        />
                                        <input
                                            type={'text'}
                                            value={answer.rightOption}
                                            className={'p-2 rounded-md border border-gray-200'}
                                            onChange={(e) => handleInputChangeMatchingR(index, e.target.value)}
                                        />
                                        <div className="flex items-center gap-2">
                                            {index != 0 ? <button onClick={() => handleRemoveMatching(index)}
                                                                  className={''}><XMarkIcon
                                                className={'w-3'} /></button> : <div className={'w-3'}></div>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>}

                        {question.type === 'trueOrFalse' && <div className={'flex flex-col justify-between gap-2 w-10'}>
                            <label className={'w-52'}>Is it correct?</label>
                            <div className="">
                                <div className="flex flex-row">
                                    <div className=" bg-gray-200 rounded-l-md p-[4px] flex flex-row">
                                        <div onClick={() => setIsTrue(true)}
                                             className={`${isTrue ? 'bg-white' : 'bg-gray-200'} rounded-md p-2 cursor-pointer`}>
                                            Yes
                                        </div>
                                    </div>
                                    <div className=" bg-gray-200 rounded-r-md flex flex-row p-[4px]">
                                        <div onClick={() => setIsTrue(false)}
                                             className={`${!isTrue ? 'bg-white' : 'bg-gray-200'} rounded-md p-2 cursor-pointer`}>
                                            No
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        <div className="mb-1">
                            <label>Description</label>
                            <input
                                type={'text'}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder={'Enter Answer Description'}
                                className={'p-2 rounded-md border border-gray-200'}
                            />
                        </div>

                        {isLoading ? <button className={'btn btn-primary btn-sm w-28 py-0'}><AppLoader width={'w-5'}
                                                                                                       height={'h-10'} />
                        </button> : <button onClick={handleSubmitMatching} disabled={disableButton()}
                                            className={'btn btn-primary btn-sm w-28 py-3'}>Save</button>}
                    </div>
                </>
            </Modal>

        </>
    )
}
