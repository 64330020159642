import React, { useEffect, useState } from 'react';
import { Modal } from '@/components/modal/modal';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import * as z from 'zod';
import { InputField } from '@/components/input';
import { Form } from '@/components/form';
import { useClassCreateMutation } from '@/app/class/redux/api';

const schema = z.object({
    topic: z.string().min(1, 'Topic is required'),
    description: z.string().min(1, 'Description is required'),
});

export type ClassInput = z.TypeOf<typeof schema>;

export const CreateClassModal = () => {

    const { user } = useAppSelector((state) => state.userState);

    const [open, setOpen] = useState(false);

    const [onCreate,  { isLoading, isSuccess }] = useClassCreateMutation();

    useEffect(() => {
        if(isSuccess) {
            setOpen(false);
        }
    }, [isSuccess]);


    return (
        <>
            {user?.role === 'admin' && <div onClick={() => setOpen(true)}
                                            className="btn btn-primary btn-sm">
                <PlusIcon className="icon-sm w-6" />
                <p className={'flex flex-row gap-1'}>New Class</p>
            </div>}

            <Modal opened={open} onClose={() => setOpen(false)} title={'Create Class'} className={'max-w-lg'}>
                <Form<ClassInput, typeof schema> schema={schema} onSubmit={(formData) => onCreate(formData)}>
                    {({ register, formState }) => (
                        <>
                            <div className='space-y-4'>
                                <div className='grid grid-cols-1 gap-2'>
                                    <div className="col-span-1">
                                        <InputField
                                            type='text'
                                            label='Topic'
                                            loading={isLoading}
                                            error={formState.errors.topic}
                                            registration={register('topic')}

                                        />
                                    </div>
                                </div>
                                <InputField
                                    type="text"
                                    label='Description'
                                    loading={isLoading}
                                    error={formState.errors.description}
                                    registration={register('description')}

                                />
                            </div>

                            <button type='submit' disabled={isLoading}
                                    className='mt-6 btn btn-primary btn-sm bg-primary w-full'>
                                Create
                            </button>
                        </>
                    )}
                </Form>
            </Modal>

        </>
    )
}
