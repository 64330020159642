import baseApi from '@/core/lib/base';
import { ResponseListModel, ResponseModel } from '@/core/types';
import { CategoryModel, CategoryPayload } from '@/app/categories/types';
import toast from 'react-hot-toast';
import { CategoryInput } from '@/app/categories/components/modals/create';

export const categoryApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        categoryListProvision: builder.query<
            ResponseListModel<CategoryModel>, any>({
            query: paginate => ({
                method: 'GET',
                url: `api/v2/category?isProvision=true`,
                params: paginate,
            }),
            providesTags: ['Categories'],
        }),

        categoryListQuiz: builder.query<
            ResponseListModel<CategoryModel>, any>({
            query: paginate => ({
                method: 'GET',
                url: `api/v2/category?isProvision=false`,
                params: paginate,
            }),
            providesTags: ['Categories'],
        }),

        categorySingle: builder.query<CategoryModel, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `api/v2/category/${id}`,
            }),
            providesTags: ['Category'],
        }),

        categoryCreate: builder.mutation<ResponseModel<CategoryPayload>, { isProvision: boolean, icon?: string, name: string, description: string  }>({
            query: payload => {
                const body = {
                    name: payload.name,
                    description: payload.description,
                    isProvision: payload.isProvision,
                    icon: payload.icon,
                }

                console.log(body);
                return {
                    method: 'POST',
                    url: 'api/v2/category/',
                    body: body,
                };
            },
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Category created');
                } catch (error) {}
            },
            invalidatesTags: ['Categories'],
        }),

        categoryUpdate: builder.mutation<ResponseModel<CategoryModel>, { icon?: string, name: string, description: string  }>({
            query: payload => {
                const body = {
                    name: payload.name,
                    description: payload.description,
                    icon: payload.icon,
                }

                console.log(body);
                return {
                    method: 'PATCH',
                    url: 'api/v2/category/',
                    body: body,
                };
            },
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Category created');
                } catch (error) {}
            },
            invalidatesTags: ['Categories'],
        }),

        categoryDelete: builder.mutation<ResponseModel<null>, { id: number }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `api/v2/category/${id}`,
            }),
            invalidatesTags: ['Categories'],
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Category deleted');
                } catch (error) {}
            },
        }),
    }),
});

export const {
    useCategoryListProvisionQuery,
    useCategoryListQuizQuery,
    useCategorySingleQuery,
    useCategoryUpdateMutation,
    useCategoryCreateMutation,
    useCategoryDeleteMutation,
} = categoryApi;
