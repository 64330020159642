import baseApi from '@/core/lib/base';
import { ResponseListModel, ResponseModel } from '@/core/types';

import toast from 'react-hot-toast';
import { QuestionModel, QuestionPayload, QuestionPayloadUpdate } from '@/app/questions/types';
import { CategoryModel } from '@/app/categories/types';

export const questionApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        questionList: builder.query<
            ResponseListModel<QuestionModel>, any>({
            query: paginate => ({
                method: 'GET',
                url: 'api/v2/question/',
                params: paginate,
            }),
            providesTags: ['Questions'],
        }),

        questionSingle: builder.query<QuestionModel, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `api/v2/question/${id}`,
            }),
            providesTags: ['Question'],
        }),

        categoryQuestions: builder.query<CategoryModel, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `api/v2/question/category/${id}`,
            }),
            providesTags: ['Question'],
        }),

        questionCreate: builder.mutation<ResponseModel<QuestionPayload>, QuestionPayload>({
            query: payload => ({
                method: 'POST',
                url: 'api/v2/question/with-answer',
                body: payload,
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Question created');
                } catch (error: any) {
                    toast.error(error.response.message);
                }
            },
            invalidatesTags: ['Category', 'Question', 'Categories', 'Questions'],
        }),

        questionUpdate: builder.mutation<ResponseModel<QuestionPayloadUpdate>, { id: number, data: QuestionPayloadUpdate }>({
            query: payload => ({
                method: 'PATCH',
                url: `api/v2/question/${payload.id}`,
                body: payload.data,
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Question Updated');
                } catch (error: any) {
                    toast.error(error.response.message);
                }
            },
            invalidatesTags: ['Category', 'Question'],
        }),

        questionDelete: builder.mutation<ResponseModel<null>, { id: number }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `api/v2/question/${id}`,
            }),
            invalidatesTags: ['Questions','Question', 'Category'],
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Question deleted');
                } catch (error) {}
            },
        }),
    }),
});

export const {
    useQuestionListQuery,
    useQuestionSingleQuery,
    useQuestionCreateMutation,
    useCategoryQuestionsQuery,
    useQuestionDeleteMutation,
    useQuestionUpdateMutation
} = questionApi;
