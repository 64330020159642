import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, ReactNode } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

type ModalProps = {
    opened: boolean;
    onClose(): void;
    title?: React.ReactNode;
    size?: string | number;
    className?: string;
    children: ReactNode;
}

export const Modal = (props: ModalProps) => {
    return (
        <Transition appear show={props.opened} as={Fragment}>
            <Dialog as="div" open={props.opened} onClose={props.onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                    <div className="flex items-start justify-center min-h-screen px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel as="div"
                                          className={`panel ${props.className ? props.className : 'max-w-lg'} border-0 p-0 w-${props.size} rounded-lg overflow-hidden w-full my-8 text-black dark:text-white-dark`}>
                                <div
                                    className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                    <h5 className="font-bold text-lg">{props.title}</h5>
                                    <button type="button" className="text-white-dark hover:text-dark"
                                            onClick={props.onClose}>
                                        <XMarkIcon className={'w-4'} />
                                    </button>
                                </div>
                                <div className="p-5">
                                    {props.children}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
