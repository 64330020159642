import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';
import { FieldWrapper, FieldWrapperPassThroughProps } from '../fieldwrapper';

type SelectFieldProps = FieldWrapperPassThroughProps & {
    options: { value: string; label: string }[];
    defaultValue?: string;
    loading?: boolean;
    className?: string;
    registration: Partial<UseFormRegisterReturn>;
};

const SelectField = (props: SelectFieldProps) => {
    const {
        label,
        options,
        defaultValue = '',
        loading = false,
        error,
        registration,
        className,
    } = props;

    return (
        <FieldWrapper label={label} error={error} className={'pb-3'}>
            <select
                disabled={loading}
                defaultValue={defaultValue}
                className={'input-select border px-3 py-2 rounded-md'}
                {...registration}
            >
                {label && <option value='' disabled hidden>{`Select ${label}`}</option>}

                {options?.map((option, key) => (
                    <option key={key} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </FieldWrapper>
    );
};

export default SelectField;
