import baseApi from '@/core/lib/base';
import { ResponseListModel, ResponseModel } from '@/core/types';
import toast from 'react-hot-toast';
import { ClassPayload, ClassModel, ClassQuestionPayload, ClassQuestion } from '../types';
import { LevelPayload } from '@/app/levels/types';
import { QuestionModel, QuestionPayload } from '@/app/questions/types';

export const classApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        classList: builder.query<
            ResponseListModel<ClassModel>, any>({
            query: paginate => ({
                method: 'GET',
                url: 'api/v3/class/',
                params: paginate,
            }),
            providesTags: ['CLASSES'],
        }),

        classSingle: builder.query<ClassModel, { id: string | number }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `api/v3/class/${id}`,
            }),
            providesTags: ['CLASS'],
        }),

        classCreate: builder.mutation<ClassModel[], ClassPayload>({
            query: payload => ({
                method: 'POST',
                url: 'api/v3/class/',
                body: payload,
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Class Created');
                } catch (error: any) {
                    if(error.error.data.message.isArray) {
                        toast.error(error.error.data.message.map((ite: string) => toast.error(ite) ));
                    } else {
                        toast.error(error.error.data.message);
                    }
                }
            },
            invalidatesTags: ['CLASSES'],
        }),

        classDelete: builder.mutation<ResponseModel<null>, { id: number }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `api/v3/class/${id}`,
            }),
            invalidatesTags: ['CLASSES'],
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Class Deactivated');
                } catch (error) {}
            },
        }),

        classQuestionsList: builder.query<
            ResponseListModel<ClassQuestion>, { id: string | number }>({
            query: paginate => ({
                method: 'GET',
                url: `api/v3/class-question/${paginate.id}`,
            }),
            providesTags: ['CLASS-Q'],
        }),

        classQuestionCreate: builder.mutation<ResponseModel<QuestionPayload>, { id: string | number ; data: ClassQuestionPayload }>({
            query: payload => ({
                method: 'POST',
                url: `api/v3/class-question/${payload.id}`,
                body: payload.data,
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Question created');
                } catch (error: any) {
                    toast.error(error.response.message);
                }
            },
            invalidatesTags: ['CLASS-Q'],
        }),
    }),
});

export const {
    useClassListQuery,
    useClassSingleQuery,
    useClassCreateMutation,
    useClassDeleteMutation,
    useClassQuestionsListQuery,
    useClassQuestionCreateMutation
} = classApi;
