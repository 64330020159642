import * as z from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSignupMutation } from '@/app/auth/redux';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';

const schema = z.object({
	firstname: z.string().min(1, 'First name required'),
	lastname: z.string().min(1, 'Last name required'),
	phoneNumber: z.string().min(1, 'Phone number required'),
	email: z.string().min(1, 'Email address required'),
});

export type SignupInput = z.TypeOf<typeof schema>;

export const Signup = () => {
	const navigate = useNavigate();

	const [onSignup, { isLoading, isSuccess }] = useSignupMutation();

	useEffect(() => {
		if (isSuccess) navigate('/login');
	}, [isSuccess, navigate]);

	return (
		<>
			<div>
				<h2 className='text-[32px] font-semibold text-primary/90'>
					Create account
				</h2>

				<p className='mt-1 text-lg text-gray-500'>
					Already have an acount?{' '}
					<Link to='/login' className='font-medium text-blue-600'>
						Log In
					</Link>
				</p>
			</div>

			<Form<SignupInput, typeof schema>
				schema={schema}
				onSubmit={onSignup}
				className='mt-10'
			>
				{({ register, formState }) => (
					<>
						<div className='space-y-8'>
							<div className='space-y-4'>
								<div className='grid grid-cols-2 gap-4'>
									<InputField
										type='text'
										label='First name'
										placeholder='Eg: John'
										loading={isLoading}
										error={formState.errors.firstname}
										registration={register('firstname')}

									/>

									<InputField
										type='text'
										label='Last name'
										placeholder='Eg: Doe'
										loading={isLoading}
										error={formState.errors.lastname}
										registration={register('lastname')}

									/>
								</div>

								<InputField
									type='text'
									label='Phone number'
									placeholder='Eg: 2507 XXX XXX'
									loading={isLoading}
									error={formState.errors.phoneNumber}
									registration={register('phoneNumber')}

								/>

								<InputField
									type='email'
									label='Email address'
									placeholder='your@example.com'
									loading={isLoading}
									error={formState.errors.email}
									registration={register('email')}

								/>
							</div>
						</div>

						<button
							type='submit'
							disabled ={isLoading}
							className='mt-6 w-full font-semibold uppercase btn btn-primary'
						>
							Create account
						</button>
					</>
				)}
			</Form>
		</>
	);
};
