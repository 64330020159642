import { Outlet, ScrollRestoration } from 'react-router-dom';

import Logo from '@/assets/images/logo_2.png';
import StockBanner from '@/assets/images/6.jpg';
import tri from '@/assets/images/tri.png';
import com from '@/assets/images/com.png';

export const AuthLayout = () => {
	return (
		<>
            <div className="flex p-5 lg:p-10 min-h-screen">
                <div
                    className="grid w-full grid-cols-1 lg:grid-cols-2 bg-white dark:bg-slate-800 overflow-clip relative shadow-2xl rounded-lg">
                    <img src={com} alt={'compass'} className={'w-20 absolute left-0 bottom-0'} />
                    <img src={tri} alt={'compass1'} className={'w-20 rotate-90 absolute bottom-0 left-1/2'} />
                    <img src={com} alt={'triangle2'} className={'w-20 absolute right-0 bottom-0'} />
                    <img src={tri} alt={'triangle'} className={'w-20 absolute right-0'} />
                    <div className="hidden bg-light lg:flex lg:items-center lg:justify-center">
                        <img alt="stock-banner" src={StockBanner} className="w-[80%]" />
                    </div>

                    <main className="bg-white w-full">
                        <div className="container flex p-5 flex-col justify-between gap-12 py-14 lg:px-15">
                            <div className={'flex flex-row gap-2 items-center text-lg font-extrabold text-primary'}>
                                <img alt="Uzi Logo" className={'w-10'} src={Logo} />Uzi
                            </div>

                            <div>
                                <Outlet />
                            </div>

                            <div></div>
                        </div>
                    </main>
                </div>
            </div>

            <ScrollRestoration />
        </>
    );
};
