import {UserModel, UserInput, UserCommonModel} from '@/app/user';
import { ResponseListModel, ResponseModel } from '@/core/types';
import toast from 'react-hot-toast';
import baseApi from '@/core/lib/base';

export const userApi = baseApi.injectEndpoints({
	endpoints: builder => ({
		userList: builder.query<
			ResponseListModel<UserCommonModel>, any>({
			query: paginate => ({
				method: 'GET',
				url: 'api/v1/user/',
				params: paginate,
			}),
            providesTags: ['Users'],
		}),

		userCreate: builder.mutation<ResponseModel<UserModel>, UserInput>({
			query: payload => ({
				method: 'POST',
				url: 'api/v1/user/',
				body: payload,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('User created');
				} catch (error) {}
			},
            invalidatesTags: ['Users'],
		}),

		userDelete: builder.mutation<ResponseModel<null>, { id: string }>({
			query: ({ id }) => ({
				method: 'DELETE',
				url: `api/v1/user/${id}`,
			}),
            invalidatesTags: ['Users'],
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('User deleted');
				} catch (error) {}
			},
		}),
	}),
});

export const {
	useUserListQuery,
	useUserCreateMutation,
	useUserDeleteMutation,
} = userApi;
