
import { Modal } from '@/components/modal/modal';
import { ReactNode, useState } from 'react';

type ConfirmationChildren = {
    onOpen: () => void;
};

type ConfirmationProps = {
    children: (args: ConfirmationChildren) => ReactNode;
    loading?: boolean;
    onConfirm: () => Promise<void>;
    title: string;
};

export default function Confirmation(props: ConfirmationProps) {

    const { children, loading = false, onConfirm, title = '' } = props;

    const [isOpen, setIsOpen] = useState(false);


    const onOpen = () => setIsOpen(true);
    const onHide = () => setIsOpen(false);

    return (
        <>
            {children({ onOpen })}
            <Modal title={title} className={'mt-40 max-w-md'} opened={isOpen} onClose={onHide} >
                <div className={'flex flex-col gap-4 items-center'}>
                    Are you sure you want to proceed with this action?
                    <button disabled={loading} onClick={() => onConfirm().then(onHide)} className={'btn btn-md bg-success text-white'}>
                        Confirm
                    </button>
                </div>
            </Modal>
        </>
    );
}
