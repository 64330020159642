import { authApi } from '@/app/auth';
import { userApi, userSlice } from '@/app/user';
import config from '@/config';
import {combineReducers, configureStore} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {persistReducer, persistStore, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import baseApi from '@/core/lib/base';
import themeConfigSlice from '@/store/themeConfigSlice';

const reduxPersistActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

const reducers = {
    userState: userSlice.reducer,
    themeConfig: themeConfigSlice,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
};

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['userSlice'],
};

const rootReducer = combineReducers(reducers);

const persistedReducers = persistReducer(persistConfig, rootReducer);

const middlewares = [
    baseApi.middleware,
];

export const store = configureStore({
    reducer: persistedReducers,
    devTools: config.NODE_ENV === 'development',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [...reduxPersistActions]
            }
        }).concat(middlewares),
});

export const persistor = persistStore(store);


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
