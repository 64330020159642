import { Link, Outlet, useLocation } from 'react-router-dom';
import { ClipboardDocumentListIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline';

import {
    UserCreate,
    useUserDeleteMutation,
} from '@/app/user';
import { format } from '@/utils';
import {
    TrashIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import Confirmation from '@/components/confirmation';
import { useCategoryDeleteMutation, useCategoryListQuizQuery } from '@/app/categories/redux/api';
import { CategoryModel } from '@/app/categories/types';
import { CreateCategoryModal } from '@/app/categories/components/modals/create';
import { EditCategoryModal } from '@/app/categories/components/modals/edit';

export const CategoryListQuiz = () => {
    const { user } = useAppSelector(state => state.userState);

    const location = useLocation();

    const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const params: { [key: string]: string } = {};

        searchParams.forEach((value, key) => {
            params[key] = value;
        });

        setQueryParams(params);
    }, [location.search]);

    const { isFetching, data } = useCategoryListQuizQuery(queryParams);

    const [onDelete, { isLoading: isLoadingDelete }] = useCategoryDeleteMutation();

    const [open , setOpen] = useState(false);

    const columns: TableColumn<CategoryModel>[] = [
        {
            accessor: 'name',
            title: 'Name',
            render: row => row.name,
        },
        {
            accessor: 'description',
            title: 'Description',
            render: row => <div title={row.description} className={'max-w-[130px] cursor-pointer overflow-clip'} >{row.description}</div>,
        },
        {
            accessor: 'isProvision',
            title: 'Provision',
            render: row => row.isProvision ? 'Yes' : 'No',
        },
        {
            accessor: 'status',
            title: 'Status',
            render: row => row.status,
        },
        {
            accessor: 'created_at',
            title: 'Created',
            render: row => format.dateTime(row.created_at),
        },
        {
            accessor: ' ',
            title: '',
            render: row =>
                row.id !== user?.id && (
                    <div className="flex justify-end gap-0 p-1">
                        <Confirmation
                            title={'Delete Category'}
                            loading={isLoadingDelete}
                            onConfirm={async () => {
                                await onDelete({ id: row.id });
                            }}
                        >
                            {({ onOpen }) => (
                                <button title={'Delete Category'} className={'p-2 bg-red-500 rounded-l-lg text-white'} onClick={onOpen}>
                                    <TrashIcon className="icon-sm w-5" />
                                </button>
                            )}
                        </Confirmation>
                        <EditCategoryModal category={row}  />
                        <Link to={`${row.id}`} title={'View Category Questions'} className={'p-2 bg-blue-500 rounded-r-lg text-white'}>
                            <ClipboardDocumentListIcon className="icon-sm w-5" />
                        </Link>
                    </div>
                ),
        },
    ];

    return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Quiz Categories</span>
                </li>
            </ul>
            <UserCreate open={open} onClose={() => setOpen(false)} />
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} title='Users'>
                <h1 className={'p-4 text-lg'}>Quiz Categories</h1>
                <CreateCategoryModal isProvision={false} />
            </div>

            <ExportDataTable
                data={data?.list ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Categories"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isFetching}
                showSearch={true}
            />

            <Outlet />
        </>
    );
};
