import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useClassQuestionsListQuery, useClassSingleQuery } from '@/app/class/redux/api';
import { UserCommonModel, UserModel } from '@/app/user';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { format } from '@/utils';
import Confirmation from '@/components/confirmation';
import { ArrowUpOnSquareStackIcon, PlusCircleIcon, PlusIcon, QrCodeIcon, TrashIcon } from '@heroicons/react/24/outline';
import { QuestionModel } from '@/app/questions/types';
import { CreateClassQuestionModal } from '@/app/class/components/modals/create-question';
import { ClassModel, ClassQuestion } from '@/app/class/types';
import c_1 from '@/assets/images/c_1.png';
import c_2 from '@/assets/images/c_2.png';
import c_3 from '@/assets/images/c_3.png';
import c_4 from '@/assets/images/c_4.png';
import useSocket from '@/services/websocket.service';
import { useAppSelector } from '@/store';
import toast from 'react-hot-toast';
import { Modal } from '@/components/modal/modal';
import { BsEnvelopeArrowUp } from 'react-icons/bs';
import { ArrowDropUpOutlined } from '@mui/icons-material';

interface PerformanceData {
    totalMarks: number;
    totalPossibleMarks: number;
    performance: string;
    student: UserModel;
}

export default function ClassSingle() {
    const { id } = useParams();
    return id ? <Class id={id} /> : null;
}

function Class({ id } : { id: string }) {
    const { data } = useClassSingleQuery({ id });

    const [tab, setTab] = useState(0);
    const tabs = ['Students', 'Questions', 'Class'];

    return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li>
                    <Link
                        to="/class"
                        className="before:content-['/'] text-primary hover:underline"
                    >{'  '} Class
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>{data?.topic}</span>
                </li>
            </ul>
            <div className="">
                <div className="py-3">
                    <ul className={'w-full p-1 rounded-md flex gap-1 flex-row bg-gray-100/60 text-lg'}>
                        {tabs.map((item, index) =>
                            <li key={index} onClick={() => setTab(index)} className={`p-3 ${index === tab ? 'bg-primary text-white' : 'bg-gray-100 text-black'} cursor-pointer rounded-lg`}>{item}</li>
                        )}
                    </ul>
                </div>
                {tab === 0 && data && <ClassStudents students={data.students} />}
                {tab === 1 && data && <ClassQuestions id={id} />}
                {tab === 2 && data && <ClassBody data={data} />}
            </div>
        </>
    )
}

function ClassStudents({ students } : { students: UserCommonModel[] }) {

    const columns: TableColumn<UserCommonModel>[] = [
        {
            accessor: 'username',
            title: 'User Name',
            render: row => row.username,
        },
        {
            accessor: 'email',
            title: 'Email address',
            render: row => row.email,
        },
        {
            accessor: ' ',
            title: '',
            render: row =>
                row.id && (
                    <div className='flex justify-end gap-2'>
                        <Confirmation
                            title={'Remove Student'}
                            loading={true}
                            onConfirm={async () => {}}
                        >
                            {({ onOpen }) => (
                                <button title={'Delete User'} className={'btn btn-danger btn-sm'} onClick={onOpen}>
                                    <TrashIcon className='icon-sm w-5' />
                                </button>
                            )}
                        </Confirmation>
                    </div>
                ),
        },
    ];


    return (
        <div className={''}>
            <ExportDataTable
                data={students ?? []}
                total={0}
                columns={columns}
                tableName="Levels"
                currentPage={0}
                nextPage={0}
                previousPage={0}
                lastPage={0}
                isLoading={false}
            />
        </div>
    )
}

function ClassQuestions({ id } : { id: string }) {

    const { data, isFetching } = useClassQuestionsListQuery({ id });

    const columns: TableColumn<ClassQuestion>[] = [
        {
            accessor: 'title',
            title: 'Title',
            render: row => row.title,
        },
        {
            accessor: 'marks',
            title: 'Marks',
            render: row => row.marks,
        },
        {
            accessor: 'created_at',
            title: 'Created',
            render: row => format.dateTime(row.created_at),
        },
        {
            accessor: ' ',
            title: '',
            render: row =>
                row.id && (
                    <div className="flex justify-end gap-0 p-1">

                    </div>
                ),
        },
    ];


    return (
        <div className={''}>
            <div className="flex p-1 flex-row w-full">
                <CreateClassQuestionModal classCode={id} />
            </div>
            <ExportDataTable
                data={data?.list ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Users"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isFetching}
                showSearch={true}
            />
        </div>
    )
}


function ClassBody({ data: classRoom } : { data: ClassModel }) {

    const { data, isFetching } = useClassQuestionsListQuery({ id: classRoom.id });
    const { user } = useAppSelector(state => state.userState);

    const { socket, sendMessage, receivedMessage } = useSocket();

    const [received_question, setReceivedQuestion] = useState<ClassQuestion | null>(null);
    const [joinedM, setJoinedM] = useState<string | null>(null);
    const [perfomance, setperfomance] = useState<PerformanceData[] | null>(null);

    useEffect(() => {
        // Example of joining a class
        sendMessage("joinClass", { classCode: classRoom.classCode, studentId: user?.id });
    }, [sendMessage]);

    useEffect(() => {

        if(receivedMessage) {
            if (receivedMessage.event === 'studentJoined') {
                if (receivedMessage.message.role === 'student') {
                    setJoinedM(`${receivedMessage.message.username} joined`)
                }
            }

            if (receivedMessage.event === 'receiveNewQuestion') {
                setReceivedQuestion(receivedMessage.message);
            }

            if (receivedMessage.event === 'classPerformanceUpdate') {
                setperfomance(receivedMessage.message);
            }

        }

    }, [receivedMessage]);

    const sendNewQuestion = (newQuestion: ClassQuestion) => {
        sendMessage('sendNewQuestion', { id : classRoom.id, questionId: newQuestion.id   });
    }

    useEffect(() => {
        // Set a timeout to increment the count after 3 seconds
        const timer = setTimeout(() => {
            setJoinedM(null);
        }, 5000);

        // Clean up the timeout if the component unmounts or the effect re-runs
        return () => {
            clearTimeout(timer);
        };
    }, [joinedM]);

    return (
        <div className={''}>
            <div className="flex gap-2 p-1 mb-3 flex-row w-full">
                <CreateClassQuestionModal classCode={classRoom.id} />
                { data && <FireQuestionModal fire={sendNewQuestion} questions={data.list} />}
                <ShowQrCode classRoom={classRoom} />
            </div>
            {joinedM && <div className="my-3">
                <div className="p-3 rounded bg-green-600 text-white">
                    {joinedM}
                </div>
            </div>}
            <div className="grid gap-2 grid-cols-2">
                { received_question && <div className="panel my-10 max-h-[500px]">
                    <div className="flex flex-col items-center text-lg gap-3">
                        <p>{received_question.title}</p>
                        {received_question.icon && <img src={received_question.icon} alt={received_question.title}
                                                        className="w-44 h-auto" />}
                    </div>
                    <div className="grid mt-3 gap-3 grid-cols-2">
                        {received_question.answers && received_question.answers.map((itm, index) =>
                            <div key={index}
                                 className="w-full gap-3 items-center justify-start  font-bold flex flex-row shadow-md rounded border border-gray-400/20 p-5">
                                <img src={
                                    index === 0 ? c_1 :
                                        index === 1 ? c_2 :
                                            index === 2 ? c_3 : c_4
                                } alt={itm.title} className={'w-10 h-14'} />
                                {itm.title}</div>,
                        )}
                    </div>
                </div>}

                {perfomance &&  <div className="panel my-10">
                    <div className="py-2">Class Performance</div>
                    {perfomance.map((itm, index) =>

                        <div key={index} className={'py-2 px-1 flex flex-row gap-2 items-center'}>
                            {itm.student.image ? <img alt={itm.student.name} src={itm.student.image} className="w-10 h-10 flex items-center justify-center rounded-full" /> :
                                <div className="w-10 h-10 flex items-center justify-center bg-primary/30 rounded-full text-xl font-bold">{getInitials(itm.student.name)}</div>}
                            <div className="flex flex-col gap-2">
                            <div className="font-semibold underline">{itm.student.name}</div>
                                <div className="">{itm.performance}</div>
                            </div>
                        </div>,
                    )}
                </div>}
            </div>
        </div>
    )
}

function FireQuestionModal({ questions, fire }: { questions: ClassQuestion[], fire: (val: ClassQuestion) => any }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            {<div onClick={() => setOpen(true)}
                  className="btn btn-primary btn-sm gap-2">
                <ArrowUpOnSquareStackIcon className={'w-5'} />

                <p className={'flex flex-row gap-1'}>Broadcast Question</p>
            </div>}
            <Modal title={'Broadcast question'} opened={open} onClose={() => setOpen(false)}>
                <div className="max-h-[500px] overflow-y-scroll px-4">
                    <p className={'mb-3'}>Please select a question to broadcast</p>
                    <div className="flex flex-col gap-2">
                        {questions.map((item, index) =>
                            <div key={index}
                                 className="p-2 rounded border shadow-sm flex flex-row justify-between items-center">
                            <p>{item.title}</p>

                                <div onClick={() => {
                                    fire(item);
                                    setOpen(false)
                                }} className="btn btn-sm bg-blue-500">
                                    <PlusCircleIcon className={'w-5 text-white'} /></div>
                            </div>,
                        )}
                    </div>
                </div>
            </Modal>
        </>
    )
}

function ShowQrCode({ classRoom }: { classRoom: ClassModel }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            {<div onClick={() => setOpen(true)}
                  className="btn btn-primary btn-sm gap-2">
                <QrCodeIcon className={'w-5'} />
            </div>}
            <Modal title={`Class QR Code`} opened={open} onClose={() => setOpen(false)}>
                <img src={`https://quickchart.io/qr?text=${classRoom.id}`} alt={classRoom.classCode} className="w-full overflow-y-scroll px-4" />
            </Modal>
        </>
    )
}

export function getInitials(name: string): string {
    // Split the name into words
    const words = name.trim().split(/\s+/);

    // Extract the first letter of the first word
    const firstInitial = words[0][0].toUpperCase();

    // Determine the second initial
    let secondInitial;
    if (words.length > 1) {
        // If there are multiple words, take the first letter of the last word
        secondInitial = words[words.length - 1][0].toUpperCase();
    } else {
        // If there's only one word, take the second letter of the first word
        secondInitial = words[0][1] ? words[0][1].toUpperCase() : '';
    }

    return `${firstInitial}${secondInitial}`;
}
