// src/hooks/useSocket.ts
import { useEffect, useState, useCallback, useRef } from "react";
import { io, Socket } from "socket.io-client";
import config from '@/config';

type MessagePayload = {
    classCode: string;
    studentId: string;
};

type UseSocketResult = {
    socket: Socket | null;
    sendMessage: (event: string, payload: any) => void;
    receivedMessage: { event: string; message: any  };
};

const useSocket = (): UseSocketResult => {
    const url = config.API_URL ?? '';
    const [socket, setSocket] = useState<Socket | null>(null);
    const [receivedMessage, setReceivedMessage] = useState<any>();
    const socketRef = useRef<Socket | null>(null);

    useEffect(() => {
        const socketInstance = io(url, {
            transports: ['websocket'],
        });

        socketInstance.on("connect", () => {
            console.log(`Connected: ${socketInstance.id}`);
        });

        socketInstance.on("disconnect", () => {
            console.log("Disconnected");
        });

        socketRef.current = socketInstance;
        setSocket(socketInstance);

        return () => {
            socketInstance.disconnect();
        };
    }, [url]);

    const sendMessage = useCallback((event: string, payload: any) => {
        if (socketRef.current) {
            console.log({ event, payload });
            socketRef.current.emit(event, payload);
        }
    }, []);

    const addMessageListener = useCallback((event: string) => {
        if (socketRef.current) {
            socketRef.current.on(event, (message: any) => {
                setReceivedMessage({ event ,message });
            });
        }
    }, []);

    useEffect(() => {
        if (socketRef.current) {
            addMessageListener("studentJoined");
            addMessageListener("studentLeft");
            addMessageListener("classPerformanceUpdate");
            addMessageListener("receiveNewQuestion");
            addMessageListener("receivedMarks");
            addMessageListener("error");
        }
    }, [addMessageListener]);

    return {
        socket,
        sendMessage,
        receivedMessage,
    };
};

export default useSocket;
