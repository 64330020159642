import React, { useEffect, useState } from 'react';
import { Modal } from '@/components/modal/modal';
import { CameraIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import * as z from 'zod';
import { InputField } from '@/components/input';
import { useLevelCreateMutation, useLevelsQQuery } from '@/app/levels/redux/api';
import { Form } from '@/components/form';
import { LevelModel } from '@/app/levels/types';

const schema = z.object({
    name: z.string().min(1, 'Name is required'),
    description: z.string().min(1, 'Description is required'),
    question_number: z.string().min(1, 'Number of Questions is required'),
});

export type LevelInput = z.TypeOf<typeof schema>;


export const CreateLevelModal = () => {

    const { user } = useAppSelector((state) => state.userState);
    const { data } = useLevelsQQuery({ pageSize:'2000' });

    const [open, setOpen] = useState(false);
    const [levelIds, setLevelIds] = useState<LevelModel[]>([]);

    const [onCreate,  { isLoading, isSuccess }] = useLevelCreateMutation();

    const [selectedImage, setSelectedImage] = useState<string | undefined>();

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                if (e.target) {
                    setSelectedImage(e.target.result as string);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const handleButtonClick = () => {
        const inputElement = document.getElementById('fileInput');
        if (inputElement) {
            inputElement.click();
        }
    };

    useEffect(() => {
        if(isSuccess) {
            setOpen(false);
        }
    }, [isSuccess]);

    const addLevelId = (newLevel: LevelModel) => {
        const updatedLevelIds = [...levelIds];
        if (!updatedLevelIds.some((level) => level.id === newLevel.id)) {
            updatedLevelIds.push(newLevel);
            setLevelIds(updatedLevelIds);
        } else {
            console.log('Level with name:', newLevel.name, 'already exists');
        }
    };

    const removeLevelId = (id: number) => {
        const updatedLevelIds = levelIds.filter((level) => level.id !== id);
        setLevelIds(updatedLevelIds);
    };

    const [isChecked, setIsChecked] = useState(false); // Initial state is false

    const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setIsChecked(event.target.checked);
    };


    return (
        <>
            {user?.role === 'admin' && <div onClick={() => setOpen(true)}
                                            className="btn btn-primary btn-sm">
                <PlusIcon className="icon-sm w-6" />
                <p className={'flex flex-row gap-1'}>New Level</p>
            </div>}

            <Modal opened={open} onClose={() => setOpen(false)} title={'Create Level'} className={'max-w-lg'}>
                <Form<LevelInput, typeof schema> schema={schema} onSubmit={(formData) => onCreate({ ids: levelIds.map((e) => e.id), data: formData, isProvision: isChecked })}>
                    {({ register, formState }) => (
                        <>
                            <div className='space-y-4'>
                                <div className='grid grid-cols-3 gap-2'>
                                    <div className="col-span-2">
                                        <InputField
                                            type='text'
                                            label='Name'
                                            loading={isLoading}
                                            error={formState.errors.name}
                                            registration={register('name')}

                                        />
                                    </div>
                                    <InputField
                                        type='number'
                                        label='Number of Questions'
                                        loading={isLoading}
                                        error={formState.errors.question_number}
                                        registration={register('question_number')}

                                    />
                                </div>
                                <div className="flex flex-row justify-start gap-3 items-center">
                                    <label>Provision Levels</label>
                                    <input type="checkbox" checked={isChecked} onChange={handleChange} />
                                </div>
                                <div className="mb-1">
                                    <label>Select Pre-requisite Levels</label>
                                    <select
                                        onChange={(e) => {
                                            const selectedLevelId = parseInt(e.target.value, 10);
                                            if (!isNaN(selectedLevelId)) {
                                                const selectedLevel = data?.list.find((i) => i.id === selectedLevelId);
                                                if (selectedLevel) {
                                                    addLevelId(selectedLevel);
                                                }
                                            } else {
                                                console.error('Invalid level ID format');
                                            }
                                        }}
                                        className={'p-2 rounded-md border border-gray-200'}
                                    >
                                        <option>Select Level</option>
                                        {data?.list.map((itm, index) => <option key={index}
                                                                                value={itm.id}>{itm.name}</option>)}
                                    </select>
                                </div>
                                <div className="flex flex-col gap-1">
                                    {levelIds.map((ite, index) => <div
                                        key={index}
                                        className="flex border py-2 px-2.5 rounded-md flex-row gap-3 justify-between items-center">
                                        <p>{ite.name}</p>
                                        <div onClick={() => removeLevelId(ite.id)} className="">
                                            <XMarkIcon className={'w-3'} />
                                        </div>
                                    </div>)}
                                </div>
                                <InputField
                                    type="text"
                                    label='Description'
                                    loading={isLoading}
                                    error={formState.errors.description}
                                    registration={register('description')}

                                />

                                <div className="flex w-full flex-col gap-1">
                                    <div className="flex w-full flex-end"><XMarkIcon
                                        onClick={() => setSelectedImage(undefined)} className={'w-5 rounded bg-green-500 text-white p-1 cursor-pointer'} />
                                    </div>
                                    <div
                                        className="border-2 h-full border-dashed border-primary/40 text-primary/50 rounded-md flex gap-2 justify-center items-center">
                                        {selectedImage ? <img src={selectedImage} onClick={handleButtonClick}
                                                              className={'cursor-pointer w-full'}
                                                              alt={'selected image'} /> :
                                            <div className={'cursor-pointer my-10 flex flex-col items-center'}
                                                 onClick={handleButtonClick}>
                                                <CameraIcon className={'w-10 '} />
                                                <input
                                                    id="fileInput"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    style={{ display: 'none' }}
                                                />
                                                <p className={'text-xs'}>Add Image</p>
                                            </div>}
                                    </div>
                                </div>

                            </div>

                            <button type='submit' disabled={isLoading}
                                    className='mt-6 btn btn-primary btn-sm bg-primary w-full'>
                                Create
                            </button>
                        </>
                    )}
                </Form>
            </Modal>

        </>
    )
}
