import baseApi from '@/core/lib/base';
import { ResponseListModel, ResponseModel } from '@/core/types';
import toast from 'react-hot-toast';
import { LevelModel, LevelPayload } from '@/app/levels/types';
import { LevelInput } from '@/app/levels/components/modals/create';

export const levelApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        levelList: builder.query<
            ResponseListModel<LevelModel>, any>({
            query: paginate => ({
                method: 'GET',
                url: 'api/v2/level/',
                params: paginate,
            }),
            providesTags: ['Levels'],
        }),

        levelsP: builder.query<
            ResponseListModel<LevelModel>, any>({
            query: paginate => ({
                method: 'GET',
                url: 'api/v2/level?isProvision=true',
                params: paginate,
            }),
            providesTags: ['Levels'],
        }),

        levelsQ: builder.query<
            ResponseListModel<LevelModel>, any>({
            query: paginate => ({
                method: 'GET',
                url: 'api/v2/level?isProvision=false',
                params: paginate,
            }),
            providesTags: ['Levels'],
        }),

        levelSingle: builder.query<LevelModel, { id: number }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `api/v2/level/${id}`,
            }),
            providesTags: ['Level'],
        }),

        levelCreate: builder.mutation<ResponseModel<LevelPayload>, { ids: number[], data: LevelInput, isProvision: boolean }>({
            query: payload => ({
                method: 'POST',
                url: 'api/v2/level/',
                body: {
                    ...payload.data,
                    prerequisiteLevelIds: payload.ids,
                    isProvision: payload.isProvision,
                },
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Level created');
                } catch (error: any) {
                    if(error.error.data.message.isArray) {
                        toast.error(error.error.data.message.map((ite: string) => toast.error(ite) ));
                    } else {
                        toast.error(error.error.data.message);
                    }
                }
            },
            invalidatesTags: ['Levels'],
        }),

        levelDelete: builder.mutation<ResponseModel<null>, { id: number }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `api/v2/level/${id}`,
            }),
            invalidatesTags: ['Levels'],
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Level deleted');
                } catch (error) {}
            },
        }),
    }),
});

export const {
    useLevelListQuery,
    useLevelSingleQuery,
    useLevelsPQuery,
    useLevelsQQuery,
    useLevelCreateMutation,
    useLevelDeleteMutation,
} = levelApi;
