export const PrivacyPolicyPage = () => {
    return (
        <div className="bg-gray-100 flex flex-col items-center gap-10 p-10">
            <div className="container px-4 py-8 m-4 mx-10 border-primary border">
                <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
                <p className="mb-4">
                    This privacy policy describes how we collect, use, and disclose your information when you use our
                    quiz app UZI.
                </p>
                <h2 className="text-2xl font-bold mb-4">Information We Collect</h2>
                <ul className="list-disc pl-4 mb-4">
                    <li>Information you provide directly:</li>
                    <ul className="list-disc pl-4 mb-2">
                        <li>This may include your name, email address, and any other information you choose to enter in
                            the App.
                        </li>
                    </ul>
                    <li>Information collected automatically:</li>
                    <ul className="list-disc pl-4 mb-2">
                        <li>This may include your device type, operating system, IP address, and browsing activity
                            within the App.
                        </li>
                    </ul>
                </ul>
                <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>
                <ul className="list-disc pl-4 mb-4">
                    <li>To operate and maintain the App.</li>
                    <li>To personalize your experience within the App.</li>
                    <li>To communicate with you about the App, including updates and promotions.</li>
                    <li>To analyze how the App is used and improve its functionality.</li>
                </ul>
                <h2 className="text-2xl font-bold mb-4">Disclosure of Your Information</h2>
                <p className="mb-4">
                    We may disclose your information to third-party vendors who help us operate the App, such as data
                    storage providers and analytics providers. We will only disclose your information to these vendors
                    to the extent necessary for them to provide their services.
                </p>
                <h2 className="text-2xl font-bold mb-4">Your Choices</h2>
                <p className="mb-4">
                    You can choose to opt out of receiving marketing communications from us. You can also access and
                    update your information within the App.
                </p>
                <h2 className="text-2xl font-bold mb-4">Data Retention</h2>
                <p className="mb-4">
                    We will retain your information for as long as necessary to provide the services offered by the App
                    and comply with applicable laws.
                </p>
                <h2 className="text-2xl font-bold mb-4">Children's Privacy</h2>
                <p className="mb-4">
                    Our App is not intended for children under the age of 13. We do not knowingly collect information
                    from children under 13. If you are a parent or guardian and you believe that your child has provided
                    us with information, please contact us and we will take steps to remove the information from our
                    systems.
                </p>
                <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
                <p className="mb-4">
                    If you have any questions about this privacy policy, please contact us at.
                </p>
            </div>
        </div>
    );
}
