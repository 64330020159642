import { Link } from 'react-router-dom';

export const Dashboard = () => {

    return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>
            </ul>
        </>
    );
};
