import { Link, Outlet, useLocation } from 'react-router-dom';
import { format } from '@/utils';
import {
    TrashIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import Confirmation from '@/components/confirmation';
import { useLevelDeleteMutation, useLevelListQuery } from '@/app/levels/redux/api';
import { LevelModel } from '@/app/levels/types';
import { CreateLevelModal } from '@/app/levels/components/modals/create';

export const LevelList = () => {
    const { user } = useAppSelector(state => state.userState);

    const location = useLocation();

    const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const params: { [key: string]: string } = {};

        searchParams.forEach((value, key) => {
            params[key] = value;
        });

        setQueryParams(params);
    }, [location.search]);

    const { isFetching, data } = useLevelListQuery(queryParams);

    const [onDelete, { isLoading: isLoadingDelete }] = useLevelDeleteMutation();

    const columns: TableColumn<LevelModel>[] = [
        {
            accessor: 'name',
            title: 'Name',
            render: row => row.name,
        },
        {
            accessor: 'description',
            title: 'Description',
            render: row => row.description,
        },
        {
            accessor: 'question_number',
            title: 'Questions',
            render: row => row.question_number,
        },
        {
            accessor: 'status',
            title: 'Status',
            render: row => row.status,
        },
        {
            accessor: 'created_at',
            title: 'Created',
            render: row => format.dateTime(row.created_at),
        },
        {
            accessor: ' ',
            title: '',
            render: row =>
                row.id !== user?.id && (
                    <div className="flex justify-end gap-0 p-1">
                        <Confirmation
                            title={'Delete Level'}
                            loading={isLoadingDelete}
                            onConfirm={async () => {
                                await onDelete({ id: row.id });
                            }}
                        >
                            {({ onOpen }) => (
                                <button title={'Delete Level'} className={'p-2 bg-red-500 rounded-lg text-white'} onClick={onOpen}>
                                    <TrashIcon className="icon-sm w-5" />
                                </button>
                            )}
                        </Confirmation>
                    </div>
                ),
        },
    ];

    return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/dashboard"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Levels</span>
                </li>
            </ul>
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} >
                <h1 className={'p-4 text-lg'}>Quiz Levels</h1>
                <CreateLevelModal />
            </div>

            <ExportDataTable
                data={data?.list ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Levels"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isFetching}
            />

            <Outlet />
        </>
    );
};
