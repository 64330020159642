import { Link, Outlet, useLocation } from 'react-router-dom';
import { PlusIcon, UsersIcon } from '@heroicons/react/24/outline';

import {
    UserCommonModel, UserCreate,
    useUserDeleteMutation,
    useUserListQuery,
} from '@/app/user';
import { format } from '@/utils';
import {
	TrashIcon,
} from '@heroicons/react/24/outline';
import { usePaginate } from '@/core/hooks';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { EyeIcon } from '@heroicons/react/16/solid';
import Confirmation from '@/components/confirmation';

export const UserList = () => {
	const { user } = useAppSelector(state => state.userState);

    const location = useLocation();

    const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const params: { [key: string]: string } = {};

        searchParams.forEach((value, key) => {
            params[key] = value;
        });

        setQueryParams(params);
    }, [location.search]);

	const { isFetching, data } = useUserListQuery(queryParams);
	const [onDelete, { isLoading: isLoadingDelete }] = useUserDeleteMutation();

    const [open , setOpen] = useState(false);

    const columns: TableColumn<UserCommonModel>[] = [
		{
			accessor: 'username',
			title: 'User Name',
			render: row => row.username,
		},
		{
			accessor: 'email',
			title: 'Email address',
			render: row => row.email,
		},
        {
            accessor: 'role',
            title: 'Role',
            render: row => row.role,
        },
		{
            accessor: 'created_at',
			title: 'Created',
			render: row => format.dateTime(row.created_at),
		},
		{
            accessor: ' ',
			title: '',
			render: row =>
				row.id !== user?.id && (
					<div className='flex justify-end gap-2'>
						<Confirmation
                            title={'Delete Account'}
							loading={isLoadingDelete}
							onConfirm={async () => {
                                await onDelete({ id: row.id.toString() });
                            }}
						>
							{({ onOpen }) => (
								<button title={'Delete User'} className={'btn btn-danger btn-sm'} onClick={onOpen}>
									<TrashIcon className='icon-sm w-5' />
								</button>
							)}
						</Confirmation>
					</div>
				),
		},
	];

	return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/dashboard"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Users</span>
                </li>
            </ul>
            <UserCreate open={open} onClose={() => setOpen(false)} />
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} title='Users'>
                <h1 className={'p-4 text-lg'}>Users</h1>
                {user?.role === 'admin' && <div onClick={() => setOpen(true)}
                                                             className="btn btn-primary btn-sm">
                    <PlusIcon className="icon-sm w-6" />
                    <p className={'flex flex-row gap-1'}>New User</p>
                </div>}
            </div>

            <ExportDataTable
                data={data?.list.filter((itm) => !(itm.email == null && itm.firstName == null)) ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Users"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isFetching}
            />

            <Outlet />
        </>
    );
};
