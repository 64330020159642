import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ClipboardDocumentListIcon, PlusIcon } from '@heroicons/react/24/outline';
import { format } from '@/utils';
import {
    TrashIcon,
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useAppSelector } from '@/store';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import Confirmation from '@/components/confirmation';
import {
    useCategoryQuestionsQuery,
    useQuestionDeleteMutation,
} from '../redux/api';
import { QuestionModel } from '@/app/questions/types';
import { EyeIcon } from '@heroicons/react/16/solid';
import { LoaderIcon } from 'react-hot-toast';
import { CreateQuestionModal } from '@/app/questions/components/modals/create';
import { AppLoader } from '@/components/loader/loader';
import { UpdateQuestionModal } from '@/app/questions/components/modals/update';

export const QuestionListFromCategory = () => {

    const { id } = useParams();

    const { user } = useAppSelector(state => state.userState);

    const { isFetching, data } = useCategoryQuestionsQuery({ id: id ?? '' });

    const category = data;


    const [onDelete, { isLoading: isLoadingDelete }] = useQuestionDeleteMutation();

    const [open , setOpen] = useState(false);

    const columns: TableColumn<QuestionModel>[] = [
        {
            accessor: 'title',
            title: 'Title',
            render: row => row.title,
        },
        {
            accessor: 'marks',
            title: 'Marks',
            render: row => row.marks,
        },
        {
            accessor: 'type',
            title: 'Type',
            render: row => row.type,
        },
        {
            accessor: 'created_at',
            title: 'Created',
            render: row => format.dateTime(row.created_at),
        },
        {
            accessor: ' ',
            title: '',
            render: row =>
                row.id !== user?.id && (
                    <div className="flex justify-end gap-0 p-1">
                        <Confirmation
                            title={'Delete Category Question'}
                            loading={isLoadingDelete}
                            onConfirm={async () => {
                                await onDelete({ id: row.id });
                            }}
                        >
                            {({ onOpen }) => (
                                <button title={'Delete Question'} className={'p-2 bg-red-500 rounded-l-lg text-white'} onClick={onOpen}>
                                    <TrashIcon className="icon-sm w-5" />
                                </button>
                            )}
                        </Confirmation>
                        <UpdateQuestionModal question={row}  />
                    </div>
                ),
        },
    ];

    const navigate = useNavigate();

    if(category != undefined) {
        return (
            <>
                <ul className="flex space-x-2 rtl:space-x-reverse">
                    <li>
                        <Link
                            to="/"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li>
                        <Link
                            to={category.isProvision ? '/provisional' : '/quiz'}
                            className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2"
                        >
                            {category.isProvision ? 'Provisional Categories' : 'Quiz Categories'}
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                        <span>{category.name}</span>
                    </li>
                </ul>
                <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'}>
                    <h1 className={'p-4 text-lg'}>{category.name} Questions</h1>
                    <CreateQuestionModal category={category} />
                </div>


                <ExportDataTable
                    data={category.questions}
                    total={category.questions.length}
                    columns={columns}
                    tableName={`${category.name} Questions`}
                    currentPage={0}
                    nextPage={0}
                    previousPage={0}
                    lastPage={0}
                    isLoading={isFetching}
                />

                <Outlet />
            </>
        )
    } else {
        return (
            <div className={'h-screen flex items-center justify-center'}>
                <AppLoader />
            </div>
        );
    }
};
