import { createBrowserRouter, Navigate } from 'react-router-dom';
import Error404 from '@/errors/4004Error';
import { Login, Logout, Signup } from '@/app/auth';
import { AuthGuard, PublicGuard } from '@/core/protector';
import { AuthLayout } from '@/layouts';
import Error500 from '@/errors/500Error';
import AppLayout from '@/layouts/AppLayout';
import { UserList } from '@/app/user';
import { CategoryListProvisional } from '@/app/categories/components';
import { QuestionList } from '@/app/questions/components/questionsList';
import { QuestionListFromCategory } from '@/app/questions/components/questionListFromCategory';
import { AuthProvider } from '@/provider/AuthProvider';
import { Dashboard } from '@/app/dashboard/components';
import { LevelList } from '@/app/levels/components/levelsList';
import { PrivacyPolicyPage } from '@/app/privacy_policy';
import { CategoryListQuiz } from '@/app/categories/components/categoryListQuiz';
import { ClassList } from '@/app/class/components/classList';
import ClassSingle from '@/app/class/components/single_class';

export const AppRoutes = createBrowserRouter([
    {
        errorElement: <Error500 />,
        children: [
            { path: '/privacy-policy', element: <PrivacyPolicyPage />},
            {
                element: <PublicGuard element={<AuthLayout />} />,
                children: [
                    { path: 'login', element: <Login /> },
                    { path: 'signup', element: <Signup /> },
                ],
            },
            {
                element: <AuthProvider><AuthGuard role={'admin'} element={<AppLayout />} /></AuthProvider>,
                children: [
                    { path: '', element: <Dashboard /> },
                    { path: 'user', element: <UserList /> },
                    { path: 'provisional', element: <CategoryListProvisional /> },
                    { path: 'quiz', element: <CategoryListQuiz /> },
                    { path: 'provisional/:id', element: <QuestionListFromCategory /> },
                    { path: 'quiz/:id', element: <QuestionListFromCategory /> },
                    { path: 'question', element: <QuestionList /> },
                    { path: 'level', element: <LevelList /> },
                    { path: 'class', element: <ClassList /> },
                    { path: 'class/:id', element: <ClassSingle /> },
                ]
            }
        ],
    },
    { path: '*', element: <Error404 /> },
])
