import { default as dayjs } from 'dayjs';

export const format = {
	date: (date: string) => dayjs(date).format('MMMM DD, YYYY'),
	dateTime: (date: string) => dayjs(date).format('MMM DD, YYYY HH:mm'),
	dateNoSpace: (date: string) => dayjs(date).format('MMMM-DD-YYYY'),

	currency: (value: Number) =>
		value.toLocaleString('RWF', {
			style: 'currency',
			currency: 'RWF',
		}),
};
