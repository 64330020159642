import * as z from 'zod';
import { useEffect } from 'react';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { useUserCreateMutation } from '@/app/user/redux';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import { useAppSelector } from '@/store';
import { Modal } from '@/components/modal/modal';
import SelectField from '@/components/select/SelectField';

const schema = z.object({
	firstName: z.string().min(1, 'First name required'),
	lastName: z.string().min(1, 'Last name required'),
	username: z.string().min(1, 'Username required'),
	phoneNumber: z.string().min(1, 'Phone number required'),
	email: z.string().min(1, 'Email address required'),
	password: z.string().min(1, 'Password is required'),
    role: z.string().min(1, 'Role is required'),
});

export type UserInput = z.TypeOf<typeof schema>;

export const UserCreate = (props: { open: boolean, onClose: () => void }) => {
    const { user } = useAppSelector((store) => store.userState);

	const [onCreate, { isLoading, isSuccess }] = useUserCreateMutation();

    useEffect(() => {
        if (isSuccess) {
            props.onClose();
        }
    }, [isSuccess]);

	return (
		<Modal
			title='New User'
			// loading={isLoading}
            size={'80%'}
            className={'max-w-xl'}
			onClose={props.onClose}

         opened={props.open}>
			<Form<UserInput, typeof schema> schema={schema} onSubmit={onCreate}>
				{({ register, formState }) => (
					<>
                        <div className='space-y-4'>
                            <div className='grid grid-cols-2 gap-4'>
                                <InputField
                                    type='text'
                                    label='First name'
                                    placeholder='Eg: John'
                                    loading={isLoading}
                                    error={formState.errors.firstName}
                                    registration={register('firstName')}

                                />

                                <InputField
                                    type='text'
                                    label='Last name'
                                    placeholder='Eg: Doe'
                                    loading={isLoading}
                                    error={formState.errors.lastName}
                                    registration={register('lastName')}

                                />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <InputField
                                    type='text'
                                    label='Username'
                                    placeholder='Your username'
                                    loading={isLoading}
                                    error={formState.errors.username}
                                    registration={register('username')}

                                />

                                <InputField
                                    type='text'
                                    label='Phone number'
                                    placeholder='Eg: 2507 XXX XXX'
                                    loading={isLoading}
                                    error={formState.errors.phoneNumber}
                                    registration={register('phoneNumber')}

                                />

                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <InputField
                                    type='email'
                                    label='Email address'
                                    placeholder='your@example.com'
                                    loading={isLoading}
                                    error={formState.errors.email}
                                    registration={register('email')}

                                />
                                <SelectField
                                    label='Role'
                                    loading={isLoading}
                                    error={formState.errors.role}
                                    registration={register('role')}
                                    options={[
                                        {
                                            label: 'Admin',
                                            value: 'admin'
                                        },
                                        {
                                            label: 'Student',
                                            value: 'student'
                                        },
                                        {
                                            label: 'Instructor',
                                            value: 'instructor'
                                        }
                                    ]}

                                />
                            </div>

                            <div className="grid grid-cols-1 gap-4">
                                <InputField
                                    type='password'
                                    label='Password'
                                    placeholder='New Password'
                                    loading={isLoading}
                                    error={formState.errors.password}
                                    registration={register('password')}

                                />
                            </div>

                        </div>

                        <button type='submit' disabled={isLoading}
                                className='mt-6 btn btn-primary btn-sm bg-primary w-full'>
                            <BookmarkIcon className='icon-sm w-6' /> Create
                        </button>
                    </>
                )}
            </Form>
        </Modal>
    );
};
